<template>
  <div>
    <location />
    <div class="page wrap-1200 course-list">
      <!-- <div class="col">
        <h3 class="subtitle">
          {{ $t("page.golfcourse.golfcourse_list") }}
        </h3>
      </div> -->
      <b-form
        @submit.prevent="onSubmit"
        class="filter-group row row-cols-1 row-cols-xl-auto g-2"
      >
        <div class="col col-sm-4">
          <select aria-label="Select country" v-model="searchData.findex">
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">
              {{ $t("text.golfcourse.tournament_in_prog") }}
            </option>
            <option value="2">
              {{ $t("text.golfcourse.event_in_prog") }}
            </option>
          </select>
        </div>
        <div class="col col-sm-4">
          <select
            aria-label="Select country"
            name="countryno"
            v-model="searchData.countryno"
          >
            <option value="">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("filter.common.usa") }}</option>
            <option value="2">{{ $t("filter.common.aus") }}</option>
            <option value="3">{{ $t("filter.common.chn") }}</option>
            <option value="4">{{ $t("filter.common.jpn") }}</option>
            <option value="5">{{ $t("filter.common.kor") }}</option>
            <option value="6">{{ $t("filter.common.ca") }}</option>
            <option value="7">{{ $t("filter.common.uk") }}</option>
            <option value="8">{{ $t("filter.common.fr") }}</option>
            <option value="9">{{ $t("filter.common.etc") }}</option>
          </select>
        </div>
        <div class="col col-sm-4">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
              :placeholder="$t('title.common.course')"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>

        <div class="col-12 me-auto mt-3 mt-xl-2 order-xl-first">
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <span class="badge me-1 bg-black">T</span>
              <span class="exp">{{
                $t("text.golfcourse.tournament_in_prog")
              }}</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="badge me-1 bg-pink">E</span>
              <span class="exp">{{ $t("text.golfcourse.event_in_prog") }}</span>
            </div>
          </div>
        </div>
      </b-form>

      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-3 gx-3 gx-lg-4 gy-5"
        v-if="total_cnt"
      >
        <div class="col" :key="index" v-for="(item, index) in items">
          <a
            class="course-name border-top-dgray fs-lg-18 more text-truncate"
            @click="
              $router.push({
                name: 'CourseView',
                params: { id: item.courseno },
              })
            "
          >
            {{ item.coursename }}
          </a>
          <div class="border-bottom-gray">
            <router-link
              :to="{
                name: 'CourseView',
                params: { id: item.courseno },
                query: {
                  countryno: searchData.countryno,
                },
              }"
              class="position-relative view"
            >
              <img :src="(item.path + '/' + item.image) | get_img" />
              <div class="mask"></div>
              <span class="icon-view"></span>

              <div class="course-state">
                <div
                  class="prog-tournament bg-black"
                  v-if="item.tourdefinelist.length"
                >
                  T
                </div>
                <div
                  class="prog-event bg-pink"
                  v-if="item.awardrulelist.length"
                >
                  E
                </div>
              </div>
            </router-link>

            <div>
              <div class="row g-0 border-bottom">
                <div class="col-6 bg-gray row g-0 align-items-center">
                  <label
                    class="align-center text-black font-weight-bold d-table-cell"
                    >{{ $t("title.golfcourse.golfcourse_difficulty") }}</label
                  >
                </div>
                <div class="col-6 d-table">
                  <p class="d-table-cell">
                    <i
                      class="material-icons-outlined"
                      :class="
                        item.field >= index ? 'text-yellow' : 'text-lightgray'
                      "
                      v-for="index in 5"
                      :key="index"
                      >star</i
                    >
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-6 bg-gray row g-0 align-items-center">
                  <label
                    class="align-center text-black font-weight-bold d-table-cell"
                    >{{ $t("title.golfcourse.green_difficulty") }}</label
                  >
                </div>
                <div class="col-6 d-table">
                  <p class="d-table-cell">
                    <i
                      class="material-icons"
                      :class="
                        item.green >= index ? 'text-green' : 'text-lightgray'
                      "
                      v-for="index in 5"
                      :key="index"
                      >flag</i
                    >
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-6 bg-gray row g-0 align-items-center">
                  <label
                    class="align-center text-black font-weight-bold d-table-cell"
                    >{{ $t("title.common.hole") }} /
                    {{ $t("title.common.par") }}</label
                  >
                </div>
                <div class="col-6 d-table">
                  <p class="d-table-cell">
                    {{ item.hole + "H" + " / " + "PAR" + item.par }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom-0">
                <div class="col-6 bg-gray row g-0 align-items-center">
                  <label
                    class="align-center text-black font-weight-bold d-table-cell"
                    >{{ $t("title.common.distance") }}</label
                  >
                </div>
                <div class="col-6 d-table">
                  <p class="d-table-cell">{{ item.distance | comma }}m</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 border-top-dgray border-bottom-gray text-center" v-else>
        {{ $t("text.common.no_data") }}
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/golfcourse";
import "@/filter/common";

export default {
  name: "CourseList",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        countryno:
          this.$route.query.countryno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].countryno) ||
          "",
        findex:
          this.$route.query.findex ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].findex) ||
          "0",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 6,
      items: [],
    };
  },
  watch: {
    "searchData.countryno": function () {
      this.searchData.pageno = 1;
      this.get_courselist();
    },
    "searchData.pageno": function () {
      this.get_courselist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_courselist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.get_courselist();
    },
  },
  methods: {
    get_courselist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_courselist(this.searchData, (flag, data) => {
        console.log(flag, data);
        this.total_cnt = data.courselistcnt;
        this.page_total = Math.ceil(data.courselistcnt / this.rows);
        this.items = data.courselist;
      });
    },
    onSubmit(event) {
      // event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          countryno: this.searchData.countryno,
        },
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    cc_img(index) {
      if (this.items[index].image !== null)
        return this.items[index].path + "/" + this.items[index].image;
      else return "/img/noccimage.jpg";
    },
  },
  created() {
    this.get_courselist();
  },
};
</script>
